import { PropsWithChildren, ReactNode } from "react";
import cn from "classnames";
import s from "./styles.module.css";

const HeroText = ({
  children,
  right = null,
}: PropsWithChildren<{ right?: ReactNode }>) => {
  return (
    <span className={cn(s.root, right && s.withRight)}>
      {children}
      {right}
    </span>
  );
};

export default HeroText;
