import s from "./styles.module.css";

const Loader = ({ style = {} }: { style?: object }) => {
  return (
    <div style={{ position: "absolute", top: "50%", left: "50%", ...style }}>
      <svg
        className={s.root}
        width="46"
        height="47"
        viewBox="0 0 46 47"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_101_1200)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M18.4 0.0566406C20.23 0.0566406 21.985 0.783603 23.279 2.0776C24.573 3.3716 25.3 5.12665 25.3 6.95664C25.3 8.78663 24.573 10.5417 23.279 11.8357C21.985 13.1297 20.23 13.8566 18.4 13.8566C16.57 13.8566 14.815 13.1297 13.521 11.8357C12.227 10.5417 11.5 8.78663 11.5 6.95664C11.5 5.12665 12.227 3.3716 13.521 2.0776C14.815 0.783603 16.57 0.0566406 18.4 0.0566406Z"
            fill="white"
          />
          <path
            d="M9.81586 15.5408C8.73753 14.4624 7.27499 13.8566 5.75 13.8566C4.22501 13.8566 2.76247 14.4624 1.68414 15.5408C0.605802 16.6191 0 18.0816 0 19.6066C0 21.1316 0.605802 22.5942 1.68414 23.6725C2.76247 24.7508 4.22501 25.3566 5.75 25.3566C7.27499 25.3566 8.73753 24.7508 9.81586 23.6725C10.8942 22.5942 11.5 21.1316 11.5 19.6066C11.5 18.0816 10.8942 16.6191 9.81586 15.5408Z"
            fill="#E9E9E9"
          />
          <path
            d="M44.9895 22.5008C44.3425 21.8538 43.465 21.4903 42.55 21.4903C41.635 21.4903 40.7575 21.8538 40.1105 22.5008C39.4635 23.1478 39.1 24.0253 39.1 24.9403C39.1 25.8553 39.4635 26.7329 40.1105 27.3799C40.7575 28.0269 41.635 28.3903 42.55 28.3903C43.465 28.3903 44.3425 28.0269 44.9895 27.3799C45.6365 26.7329 46 25.8553 46 24.9403C46 24.0253 45.6365 23.1478 44.9895 22.5008Z"
            fill="#4C4C4C"
          />
          <path
            d="M42.5341 12.7064C42.1028 12.2751 41.5178 12.0327 40.9078 12.0327C40.2978 12.0327 39.7128 12.2751 39.2814 12.7064C38.8501 13.1377 38.6078 13.7227 38.6078 14.3327C38.6078 14.9427 38.8501 15.5278 39.2814 15.9591C39.7128 16.3904 40.2978 16.6327 40.9078 16.6327C41.5178 16.6327 42.1028 16.3904 42.5341 15.9591C42.9655 15.5278 43.2078 14.9427 43.2078 14.3327C43.2078 13.7227 42.9655 13.1377 42.5341 12.7064Z"
            fill="#262626"
          />
          <path
            d="M36.4632 7.29346C36.2475 7.07779 35.955 6.95663 35.65 6.95663C35.345 6.95663 35.0525 7.07779 34.8368 7.29346C34.6212 7.50913 34.5 7.80164 34.5 8.10663C34.5 8.41163 34.6212 8.70414 34.8368 8.91981C35.0525 9.13547 35.345 9.25663 35.65 9.25663C35.955 9.25663 36.2475 9.13547 36.4632 8.91981C36.6788 8.70414 36.8 8.41163 36.8 8.10663C36.8 7.80164 36.6788 7.50913 36.4632 7.29346Z"
            fill="#191919"
          />
          <path
            d="M40.7864 32.454C39.9237 31.5913 38.7537 31.1066 37.5337 31.1066C36.3137 31.1066 35.1437 31.5913 34.281 32.454C33.4184 33.3166 32.9337 34.4866 32.9337 35.7066C32.9337 36.9266 33.4184 38.0967 34.281 38.9593C35.1437 39.822 36.3137 40.3066 37.5337 40.3066C38.7537 40.3066 39.9237 39.822 40.7864 38.9593C41.6491 38.0967 42.1337 36.9266 42.1337 35.7066C42.1337 34.4866 41.6491 33.3166 40.7864 32.454Z"
            fill="#808080"
          />
          <path
            d="M28.5527 38.204C27.69 37.3413 26.52 36.8566 25.3 36.8566C24.08 36.8566 22.91 37.3413 22.0473 38.204C21.1847 39.0666 20.7 40.2366 20.7 41.4566C20.7 42.6766 21.1847 43.8467 22.0473 44.7093C22.91 45.572 24.08 46.0566 25.3 46.0566C26.52 46.0566 27.69 45.572 28.5527 44.7093C29.4154 43.8467 29.9 42.6766 29.9 41.4566C29.9 40.2366 29.4154 39.0666 28.5527 38.204Z"
            fill="#999999"
          />
          <path
            d="M14.9702 31.6408C13.8918 30.5624 12.4293 29.9566 10.9043 29.9566C9.3793 29.9566 7.91677 30.5624 6.83843 31.6408C5.7601 32.7191 5.1543 34.1816 5.1543 35.7066C5.1543 37.2316 5.7601 38.6942 6.83843 39.7725C7.91677 40.8508 9.3793 41.4566 10.9043 41.4566C12.4293 41.4566 13.8918 40.8508 14.9702 39.7725C16.0485 38.6942 16.6543 37.2316 16.6543 35.7066C16.6543 34.1816 16.0485 32.7191 14.9702 31.6408Z"
            fill="#CCCCCC"
          />
        </g>
        <defs>
          <clipPath id="clip0_101_1200">
            <rect
              width="46"
              height="46"
              fill="white"
              transform="translate(0 0.0566406)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default Loader;
