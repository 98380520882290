import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import html2canvas from 'html2canvas'
import Home from "./pages/Home";
import AgencyPlatform from "./pages/AgencyPlatform";
import reportWebVitals from "./reportWebVitals";
import Header from "./components/Header";

import "./index.css";
import Pending from "./pages/Pending";
import Firmware from "./pages/Firmware";
import Monitoring from "./pages/Monitoring";
import Rates from "./pages/Rates";
import Calculator from "./pages/Calculator";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" Component={Home} />
        <Route path="/agency-platform" Component={AgencyPlatform} />
        <Route path="/custom-firmware" Component={Firmware} />
        <Route path="/monitoring" Component={Monitoring} />
        <Route path="/rates" Component={Rates} />
        <Route path="/calculator" Component={Calculator} />
        <Route path="/pending/:type" Component={Pending} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
