import { PropsWithChildren } from "react";
import cn from "classnames";

import s from "./styles.module.css";
import { Link } from "react-router-dom";

const Button = ({
  children,
  href,
  invert,
  blue,
  wide,
  small,
  gray,
  square,
  height,
  onClick,
  tagName,
  type,
}: PropsWithChildren<{
  tagName?: string;
  type?: string;
  href?: string;
  invert?: boolean;
  blue?: boolean;
  wide?: boolean;
  small?: boolean;
  gray?: boolean;
  square?: boolean;
  height?: number;
  onClick?: () => void;
}>) => {
  const TagName = tagName || (href === undefined ? "a" : Link);

  return (
    <TagName
      onClick={onClick}
      // @ts-ignore
      to={href}
      type={type}
      className={cn(
        s.root,
        invert && s.invert,
        blue && s.blue,
        wide && s.wide,
        small && s.small,
        gray && s.gray,
        square && s.square
      )}
      style={{ height }}
    >
      {children}
    </TagName>
  );
};

export default Button;
