import { forwardRef, PropsWithChildren } from "react";
import cn from "classnames";
import s from "./styles.module.css";

const FullScreen = forwardRef(
  (
    {
      children,
      center,
      eventually,
      white,
      middle,
      fixed,
      opacity,
      zIndex,
    }: PropsWithChildren<{
      center?: boolean;
      eventually?: boolean;
      white?: boolean;
      middle?: boolean;
      fixed?: boolean;
      opacity?: number;
      zIndex?: number;
    }>,
    ref
  ) => {
    return (
      <div
        // @ts-ignore
        ref={ref}
        className={cn(
          s.root,
          center && s.center,
          eventually && s.eventually,
          white && s.white,
          middle && s.middle,
          fixed && s.fixed
        )}
        style={{ opacity, zIndex }}
      >
        {children}
      </div>
    );
  }
);

export default FullScreen;
