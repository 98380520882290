import { PropsWithChildren } from "react";
import cn from "classnames";
import s from "./styles.module.css";

const Input = ({
  children,
  invert,
  placeholder,
  onChange,
  value,
  name,
}: PropsWithChildren<{
  invert?: boolean;
  placeholder?: string;
  onChange?: (value: string) => void;
  value?: string;
  name?: string;
}>) => {
  return (
    <input
      name={name}
      type="text"
      placeholder={placeholder}
      className={cn(s.root, invert && s.invert)}
      onChange={(e) => onChange && onChange(e.target.value)}
      value={value}
    >
      {children}
    </input>
  );
};

export default Input;
