import Breadcrumbs from "../components/Breadcrumbs";
import HeroText from "../components/HeroText";
import Container from "../components/Container";
import Text from "../components/Text";
import Button from "../components/Button";
import TopLineContainer from "../components/TopLineContainer";
import Column from "../components/Column";
import s from "./agency-platform.module.css";
import Table from "../components/Table";
import Footer from "../components/Footer";
import React from "react";
import Feature from "../components/Feature";
import SecondaryText from "../components/SecondaryText";
import useMediaQuery from "../useMediaQuery";
const Monitoring = () => {
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const fontSize = isDesktop ? 32 : 21;
  return (
    <>
      <div style={{ marginTop: isDesktop ? 150 : 80 }} />
      <Container>
        <Breadcrumbs titles={["Система удаленного управления"]} />
      </Container>
      <Container>
        <HeroText
          right={isDesktop && <Button href="/pending/monitoring">Подключить ↓</Button>}
        >
          <span>Infinity Monitoring</span>
          {!isDesktop && (
            <div style={{ marginTop: 20 }}>
              <Button href="/pending/monitoring">Подключить →</Button>
            </div>
          )}
        </HeroText>
      </Container>
      <Container>
        <TopLineContainer noPadding>
          <Table
            columns={[]}
            data={[
              [
                <SecondaryText size={fontSize}>1.</SecondaryText>,
                <Text>
                  Управление всеми возможностями нашей прошивки для всех ваших
                  ASIC в одном окне
                </Text>,
              ],
              [
                <SecondaryText size={fontSize}>2.</SecondaryText>,
                <Text>
                  ERP система управления хостингом, с автоматизированной
                  системой постановки задач сотрудникам
                </Text>,
              ],
              [
                <SecondaryText size={fontSize}>3.</SecondaryText>,
                <Text>
                  Возможность синхронизации с любыми ASIC на алгоритме SHA256.
                </Text>,
              ],
              [
                <SecondaryText size={fontSize}>4.</SecondaryText>,
                <Text>
                  Специализированная CRM система взаимодействия с клиентами.
                  Встроенный электронный документооборот.
                </Text>,
              ],
            ]}
          >
            <colgroup>
              <col width={isDesktop ? "30%" : "20%"} />
            </colgroup>
          </Table>
        </TopLineContainer>
      </Container>
      <div style={{ height: 125 }} />
      <Footer background="black" />
    </>
  );
};

export default Monitoring;
