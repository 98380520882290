import s from "./styles.module.css";
import { PropsWithChildren } from "react";
import cn from "classnames";
const TopLineContainer = ({
  children,
  noPadding,
}: PropsWithChildren<{ noPadding?: boolean }>) => {
  return <div className={cn(s.root, noPadding && s.noPadding)}>{children}</div>;
};

export default TopLineContainer;
