import s from "./styles.module.css";
import cn from "classnames";
import SecondaryText from "../SecondaryText";
import Text from "../Text";
import { PropsWithChildren, ReactNode } from "react";
const Table = ({
  columns,
  data,
  children,
  borderTop,
  valign,
}: PropsWithChildren<{
  columns: ReactNode[];
  data: ReactNode[][];
  borderTop?: boolean;
  valign?: string;
}>) => {
  return (
    <table
      cellPadding={0}
      cellSpacing={0}
      className={cn(s.root, borderTop && s.borderTop)}
    >
      {children}
      <thead>
        <tr>
          {columns.map((c) => (
            <th style={{ width: `${100 / columns.length}%` }}>
              <SecondaryText>{c}</SecondaryText>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row) => (
          <tr>
            {row.map((v) => (
              // @ts-ignore
              <td valign={valign}>
                <Text>{v}</Text>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
