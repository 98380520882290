import FullScreen from "../components/FullScreen";
import Loader from "../components/Loader";
import s from "./pending.module.css";
import TitleText from "../components/TitleText";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect } from "react";

const types = ["agency-platform", "custom-firmware", "monitoring"];
const dates = ["Июнь 2023", "Сентябрь 2023", "Январь 2024"];
const Pending = () => {
  const { type } = useParams();
  const navigate = useNavigate();

  const index = types.indexOf(String(type));
  useEffect(() => {
    if (index === -1) {
      navigate("/");
    }
  }, [index]);
  if (index === -1) {
    return null;
  }

  const date = dates[index];

  return (
    <FullScreen center>
      <Loader style={{ transform: "scale(3)" }} />
      <div className={s.footer}>
        <TitleText>Дата выхода: {date}</TitleText>
      </div>
    </FullScreen>
  );
};

export default Pending;
