import TitleText from "../TitleText";
import SecondaryText from "../SecondaryText";
import { PropsWithChildren, ReactNode } from "react";
import s from "./styles.module.css";
const Feature = ({
  icon,
  title,
  children,
}: PropsWithChildren<{ title: ReactNode; icon: ReactNode }>) => {
  return (
    <div className={s.root}>
      <div className={s.icon}>{icon}</div>
      <div className={s.content}>
        <div className={s.title}>
          <TitleText>{title}</TitleText>
        </div>
        <div>
          <SecondaryText white>{children}</SecondaryText>
        </div>
      </div>
    </div>
  );
};

export default Feature;
