import s from "./styles.module.css";
import Container from "../Container";

const Footer = ({ background }: { background?: string }) => {
  return (
    <footer className={s.root} style={{ background }}>
      <Container>
        <div className={s.container}>
          <div>Infinity Group © {new Date().getFullYear()}.</div>
          <div>Все права защищены.</div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
