import { PropsWithChildren, ReactNode, useCallback, useState } from "react";
import cn from "classnames";
import s from "./styles.module.css";
import TitleText from "../TitleText";
import { Link } from "react-router-dom";

const Menu = ({ onClick, open }: { onClick: () => void; open: boolean }) => {
  return (
    <div className={cn(s.root, open && s.open)}>
      <ul>
        <li>
          <Link to="/" onClick={onClick}>
            <TitleText>Главная</TitleText>
          </Link>
        </li>
        <li>
          <Link to="/rates" onClick={onClick}>
            <TitleText>Тарифы</TitleText>
          </Link>
        </li>
        <li>
          <Link to="/calculator" onClick={onClick}>
            <TitleText>Калькулятор</TitleText>
          </Link>
        </li>
        <li>
          <Link to="/agency-platform" onClick={onClick}>
            <TitleText>Агентская платформа</TitleText>
          </Link>
        </li>
        <li>
          <Link to="/custom-firmware" onClick={onClick}>
            <TitleText>Кастомная прошивка</TitleText>
          </Link>
        </li>
        <li>
          <Link to="/monitoring" onClick={onClick}>
            <TitleText>Система удаленного управления</TitleText>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export const MenuButton = ({ onClick }: { onClick: () => void }) => (
  <div className={s.button} onClick={onClick}>
    <svg
      width="30"
      height="20"
      viewBox="0 0 30 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.796631" width="30" height="1" fill="white" />
      <rect y="9.79663" width="15" height="1" fill="white" />
      <rect y="18.7966" width="30" height="1" fill="white" />
    </svg>
  </div>
);

export default Menu;
