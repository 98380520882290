import s from "./styles.module.css";
import Input from "../Input";
import Button from "../Button";
import useMediaQuery from "../../useMediaQuery";
import { FormEvent, useCallback } from "react";

const ContactForm = () => {
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const handleSubmit = useCallback((e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    fetch("https://getform.io/f/9aa87c65-51de-48ca-9542-a8341e8be538", {
      method: "POST",
      // @ts-ignore
      body: new FormData(e.target),
      headers: {
        Accept: "application/json",
      },
    })
      .then(() =>
        alert(
          "Спасибо за интерес! В ближайшее время с Вами свяжется наш менеджер"
        )
      )
      .catch(() => {
        alert("Что-то пошло не так, уже разбираемся");
      });
  }, []);

  return (
    <form className={s.root} onSubmit={handleSubmit} acceptCharset="UTF-8">
      <input type="hidden" name="_gotcha" style={{ display: "none" }} />

      <div>
        <Input placeholder="Имя" name="name" />
      </div>
      <div>
        <Input placeholder="Телефон" name="phone" />
      </div>
      <div>
        <Button
          tagName="button"
          type="submit"
          blue
          wide
          height={isDesktop ? undefined : 55}
        >
          Связаться
        </Button>
      </div>
    </form>
  );
};

export default ContactForm;
