import { PropsWithChildren, ReactNode } from "react";

import s from "./styles.module.css";
import Text from "../Text";
import Loader from "../Loader";
import cn from "classnames";
const Card = ({
  children,
  title,
  titleRight,
  height,
  loading,
}: PropsWithChildren<{
  title: ReactNode;
  height?: number;
  loading?: boolean;
  titleRight?: boolean;
}>) => {
  return (
    <div className={s.root} style={{ minHeight: height }}>
      {loading && <Loader />}
      <Text>
        <div className={cn(s.title, titleRight && s.titleRight)}>{title}</div>
      </Text>
      <div className={s.content}>{children}</div>
    </div>
  );
};

export const InnerCard = ({ children }: PropsWithChildren) => {
  return <div className={s.inner}>{children}</div>;
};

export default Card;
