import { useEffect, useState } from "react";

function useScroll() {
  const [pos, setPos] = useState(0);
  useEffect(() => {
    const listener = () => setPos(window.scrollY);

    listener();

    window.addEventListener("scroll", listener, { passive: true });

    return () => {
      window.removeEventListener("scroll", listener);
    };
  }, []);

  return pos;
}

export default useScroll;
