import Breadcrumbs from "../components/Breadcrumbs";
import HeroText from "../components/HeroText";
import Container from "../components/Container";
import Text from "../components/Text";
import Button from "../components/Button";
import TopLineContainer from "../components/TopLineContainer";
import Column from "../components/Column";
import s from "./agency-platform.module.css";
import Table from "../components/Table";
import Footer from "../components/Footer";
import React, { PropsWithChildren } from "react";
import TitleText from "../components/TitleText";
import SecondaryText from "../components/SecondaryText";
import { useCurrentHashPrice } from "./Home";
import useMediaQuery from "../useMediaQuery";
const SmallTitle = ({ children }: PropsWithChildren) => (
  <TitleText>
    <small>{children}</small>
  </TitleText>
);
const Rates = () => {
  const currentHashPrice = useCurrentHashPrice();
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const TitleComponent = isDesktop ? TitleText : SmallTitle;

  return (
    <>
      <div style={{ marginTop: 150 }} />
      <Container>
        <Breadcrumbs titles={["Тарифы"]} />
      </Container>
      <Container>
        <TitleComponent>
          Мы первыми на рынке представили открытую дифференцированную тарифную
          сетку
        </TitleComponent>
      </Container>
      <Container>
        <div style={{ marginBottom: 220, marginTop: 60, width: "100%" }}>
          <Table
            columns={["Статус", "Hashrate", "Pool fee", "Комиссия агента"]}
            data={[
              [
                "1.",
                "<1 Ph",
                "1%",
                <div>
                  {(currentHashPrice * 0.99).toFixed(9)}{" "}
                  <sup>
                    <SecondaryText>BTC/Th/s</SecondaryText>
                  </sup>
                </div>,
              ],
              [
                "2.",
                <span>1..10&nbsp;Ph</span>,
                "0,9%",
                <div>
                  {(currentHashPrice * 0.991).toFixed(9)}{" "}
                  <sup>
                    <SecondaryText>BTC/Th/s</SecondaryText>
                  </sup>
                </div>,
              ],
              [
                "3.",
                <span>10..100&nbsp;Ph</span>,
                "0,7%",
                <div>
                  {(currentHashPrice * 0.993).toFixed(9)}{" "}
                  <sup>
                    <SecondaryText>BTC/Th/s</SecondaryText>
                  </sup>
                </div>,
              ],
              [
                "4.",
                <span>100..300&nbsp;Ph</span>,
                "0,5%",
                <div>
                  {(currentHashPrice * 0.995).toFixed(9)}{" "}
                  <sup>
                    <SecondaryText>BTC/Th/s</SecondaryText>
                  </sup>
                </div>,
              ],
              [
                "5.",
                <span>&gt;300&nbsp;Ph</span>,
                "0,3%",
                <div>
                  {(currentHashPrice * 0.997).toFixed(9)}{" "}
                  <sup>
                    <SecondaryText>BTC/Th/s</SecondaryText>
                  </sup>
                </div>,
              ],
            ]}
          />
          <br />
          <SecondaryText>* cистема выплат FPPS</SecondaryText>
        </div>
      </Container>
      <Footer background="black" />
    </>
  );
};

export default Rates;
