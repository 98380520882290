import { PropsWithChildren } from "react";
import cn from "classnames";
import s from "./styles.module.css";

const SecondaryText = ({
  children,
  white,
  size,
}: PropsWithChildren<{ white?: boolean; size?: number }>) => {
  return (
    <span
      className={cn(s.root, white && s.white)}
      style={size ? { fontSize: size } : undefined}
    >
      {children}
    </span>
  );
};

export default SecondaryText;
