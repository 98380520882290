import SecondaryText from "../SecondaryText";
import s from "./styles.module.css";
import { Link } from "react-router-dom";
import {ReactNode} from "react";

const Breadcrumbs = ({ titles }: { titles: ReactNode[] }) => {
  return (
    <SecondaryText>
      <ul className={s.root}>
        <li>
          <Link to={"/"}>Главная</Link>
        </li>
        {titles.map((title) => (
          <li>{title}</li>
        ))}
      </ul>
    </SecondaryText>
  );
};

export default Breadcrumbs;
