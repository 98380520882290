import { PropsWithChildren } from "react";
import cn from "classnames";
import s from "./styles.module.css";

const Column = ({
  children,
  half,
  width,
}: PropsWithChildren<{ half?: boolean; width?: string | number }>) => {
  return (
    <div className={cn(s.root, half && s.half, width===0&&s.shrink)} style={{ width }}>
      {children}
    </div>
  );
};

export default Column;
