import Breadcrumbs from "../components/Breadcrumbs";
import HeroText from "../components/HeroText";
import Container from "../components/Container";
import Text from "../components/Text";
import Button from "../components/Button";
import TopLineContainer from "../components/TopLineContainer";
import Column from "../components/Column";
import s from "./agency-platform.module.css";
import Table from "../components/Table";
import Footer from "../components/Footer";
import React, {ReactNode} from "react";
import useMediaQuery from "../useMediaQuery";
import Card, { InnerCard } from "../components/Card";
import SecondaryText from "../components/SecondaryText";

const data = [
  [
    <div className={s.cell} style={{ color: "#92A4AC" }}>
      <svg
        width="20"
        height="19"
        viewBox="0 0 20 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_145_3462"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="20"
          height="19"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 18.0035L0 0H19.4128V18.0035H0Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_145_3462)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19.4107 12.8714C19.4107 12.0676 18.9337 11.3887 18.2731 11.1329V4.73686L19.4107 4.22775L9.70318 0L-0.0043335 4.22775L9.70318 8.45553L17.1355 5.23268V11.1329C16.4749 11.3887 15.9979 12.0676 15.9979 12.8714C15.9979 13.6074 16.3983 14.2391 16.9725 14.5376L16.0274 17.6153L17.1061 18.006L17.7043 16.0579L18.3025 18.006L19.3812 17.6153L18.4361 14.5376C19.0103 14.2391 19.4107 13.6074 19.4107 12.8714Z"
            fill="#92A4AC"
          />
        </g>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.58539 7.55396V9.16596C4.58539 10.9259 6.78615 12.253 9.70459 12.253C12.623 12.253 14.8238 10.9259 14.8238 9.16596V7.55396L9.70459 9.78518L4.58539 7.55396Z"
          fill="#92A4AC"
        />
      </svg>
      <span>neophyte</span>
    </div>,
    "0 … 10 Ph/s",
    "0,7",
    "до 0,3",
  ],
  [
    <div className={s.cell} style={{ color: "#A87965" }}>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.93529 0C3.10565 0 0.00115967 1.40029 0.00115967 3.12772L0.00115967 3.80622C0.00115967 5.53372 3.10565 6.93394 6.93529 6.93394C10.7646 6.93394 13.869 5.53372 13.869 3.80622V3.12772C13.869 1.40029 10.7646 0 6.93529 0Z"
          fill="#A87965"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.93413 7.87411C3.59141 7.87411 0.801137 6.87589 0.145965 5.54712C0.0505121 5.74046 0 5.94078 0 6.14629L0 6.78112C0 8.39738 3.10449 9.70748 6.93413 9.70748C10.7634 9.70748 13.8679 8.39738 13.8679 6.78112V6.14629C13.8679 5.94078 13.8174 5.74046 13.7219 5.54712C13.0667 6.87589 10.2768 7.87411 6.93413 7.87411Z"
          fill="#A87965"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.93706 12.0339C3.59434 12.0339 0.804067 11.0356 0.148895 9.70703C0.0534418 9.90057 0.00292969 10.1009 0.00292969 10.3064L0.00292969 10.9409C0.00292969 12.5571 3.10742 13.8674 6.93706 13.8674C10.7663 13.8674 13.8708 12.5571 13.8708 10.9409V10.3064C13.8708 10.1009 13.8204 9.90057 13.7248 9.70703C13.0697 11.0356 10.2798 12.0339 6.93706 12.0339Z"
          fill="#A87965"
        />
      </svg>
      <span>bronze</span>
    </div>,
    "10 … 100 Ph/s",
    "0,6",
    "до 0,4",
  ],
  [
    <div className={s.cell} style={{ color: "#A4DAF2" }}>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.70155 0C3.00094 0 0.0010376 1.37859 0.0010376 3.07925L0.0010376 3.74724C0.0010376 5.44797 3.00094 6.82649 6.70155 6.82649C10.4018 6.82649 13.4017 5.44797 13.4017 3.74724V3.07925C13.4017 1.37859 10.4018 0 6.70155 0Z"
          fill="#97D2EC"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.7035 8.33547C3.47341 8.33547 0.777137 7.28512 0.144038 5.88696C0.0518011 6.0904 0.00299072 6.30118 0.00299072 6.51742L0.00299072 7.1854C0.00299072 8.88606 3.00289 10.2646 6.7035 10.2646C10.4038 10.2646 13.4037 8.88606 13.4037 7.1854V6.51742C13.4037 6.30118 13.3549 6.0904 13.2626 5.88696C12.6295 7.28512 9.9336 8.33547 6.7035 8.33547Z"
          fill="#97D2EC"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.70051 11.7737C3.47042 11.7737 0.774146 10.7233 0.141048 9.3252C0.0488104 9.52885 0 9.7397 0 9.95587L0 10.6236C0 12.3243 2.9999 13.7031 6.70051 13.7031C10.4008 13.7031 13.4007 12.3243 13.4007 10.6236V9.95587C13.4007 9.7397 13.3519 9.52885 13.2596 9.3252C12.6265 10.7233 9.93061 11.7737 6.70051 11.7737Z"
          fill="#97D2EC"
        />
      </svg>
      <span>silver</span>
    </div>,
    "100 … 300 Ph/s",
    "0,5",
    "до 0,5",
  ],
  [
    <div className={s.cell} style={{ color: "#F0BD68" }}>
      <svg
        width="14"
        height="15"
        viewBox="0 0 14 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.93645 0.574219C3.10681 0.574219 0.00231934 1.97451 0.00231934 3.70194L0.00231934 4.38044C0.00231934 6.10794 3.10681 7.50816 6.93645 7.50816C10.7657 7.50816 13.8702 6.10794 13.8702 4.38044V3.70194C13.8702 1.97451 10.7657 0.574219 6.93645 0.574219Z"
          fill="#F0BD68"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.93516 8.44808C3.59245 8.44808 0.802175 7.44986 0.147003 6.12109C0.0515497 6.31443 0.0010376 6.51475 0.0010376 6.72026L0.0010376 7.35509C0.0010376 8.97135 3.10553 10.2815 6.93516 10.2815C10.7644 10.2815 13.8689 8.97135 13.8689 7.35509V6.72026C13.8689 6.51475 13.8185 6.31443 13.723 6.12109C13.0678 7.44986 10.2779 8.44808 6.93516 8.44808Z"
          fill="#F0BD68"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.93413 12.6081C3.59141 12.6081 0.801137 11.6099 0.145965 10.2812C0.0505121 10.4748 0 10.6752 0 10.8806L0 11.5151C0 13.1313 3.10449 14.4416 6.93413 14.4416C10.7634 14.4416 13.8679 13.1313 13.8679 11.5151V10.8806C13.8679 10.6752 13.8174 10.4748 13.7219 10.2812C13.0667 11.6099 10.2768 12.6081 6.93413 12.6081Z"
          fill="#F0BD68"
        />
      </svg>
      <span>gold</span>
    </div>,
    "300 … 700 Ph/s",
    "0,4",
    "до 0,6",
  ],
  [
    <div className={s.cell} style={{ color: "#31B2F9" }}>
      <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_145_3479"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="2"
          width="17"
          height="12"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 2.53027L0 13.4073H16.8782V2.53027H0Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_145_3479)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.0912094 5.73343L5.94876 13.2853C6.17315 13.5749 6.62835 13.3018 6.47417 12.9702L2.96255 5.41836C2.91218 5.30986 2.80254 5.24023 2.682 5.24023H0.336174C0.0799007 5.24023 -0.0649056 5.53203 0.0912094 5.73343Z"
            fill="#31B2F9"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16.8165 4.16689L15.7083 2.66626C15.5691 2.47776 15.2773 2.50675 15.1784 2.71878L14.4794 4.21954C14.385 4.42254 14.5345 4.65451 14.7601 4.65451H16.567C16.8204 4.65424 16.9659 4.36922 16.8165 4.16689Z"
            fill="#31B2F9"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1.17023 2.65569L0.0550799 4.16589C-0.0945534 4.36821 0.0513218 4.65337 0.30425 4.65337H2.12171C2.34705 4.65337 2.49665 4.42167 2.40225 4.21867L1.70018 2.70862C1.60158 2.49629 1.30948 2.46719 1.17023 2.65569Z"
            fill="#31B2F9"
          />
        </g>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.8943 1.55839L14.1621 0.566918C14.1039 0.488065 14.0112 0.441406 13.9129 0.441406H9.75902C9.47151 0.441406 9.33963 0.796501 9.55877 0.981161C10.8144 2.0391 12.5116 3.46472 13.403 4.21329C13.5588 4.34425 13.797 4.29119 13.8826 4.10749L14.9254 1.86757C14.9726 1.76656 14.9606 1.64823 14.8943 1.55839Z"
          fill="#31B2F9"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.13317 0.441406H2.9642C2.86573 0.441406 2.77309 0.488065 2.71489 0.566918L1.99007 1.54841C1.9238 1.63812 1.9118 1.75673 1.95883 1.85774L3.00485 4.10762C3.09025 4.29147 3.32822 4.3448 3.48419 4.21384C4.38061 3.46233 6.08995 2.02831 7.33328 0.981264C7.55242 0.796637 7.42072 0.441406 7.13317 0.441406Z"
          fill="#31B2F9"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.73712 5.67521L8.1624 15.1918C8.27283 15.4292 8.61316 15.4294 8.7236 15.192L13.1545 5.67535C13.2491 5.47221 13.0994 5.24048 12.8738 5.24048H4.0178C3.79235 5.24038 3.64272 5.47207 3.73712 5.67521Z"
          fill="#31B2F9"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.3644 4.11303C11.2593 3.18459 9.67514 1.85331 8.64284 0.982179C8.52755 0.884846 8.35961 0.884846 8.24404 0.982179C7.23033 1.83803 5.63359 3.17847 4.51952 4.11303C4.29972 4.29728 4.43198 4.65275 4.7198 4.65275H12.1642C12.4518 4.65275 12.5841 4.29742 12.3644 4.11303Z"
          fill="#31B2F9"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.9404 13.2692L16.7852 5.73368C16.9414 5.53227 16.7965 5.24048 16.5402 5.24048H14.2038C14.0833 5.24048 13.9736 5.30987 13.9232 5.41833L10.4147 12.9539C10.2605 13.2856 10.7156 13.5587 10.9404 13.2692Z"
          fill="#31B2F9"
        />
      </svg>
      <span>diamond</span>
    </div>,
    ">700 Ph/s",
    "0,3",
    "до 0,7",
  ],
];

const MobileInnerCard = ({
  title,
  value,
}: {
  title: string;
  value: ReactNode;
}) => (
  <div style={{ flexBasis: "50%" }}>
    <div>
      <SecondaryText>{title}</SecondaryText>
    </div>
    <div>
      <Text>{value}</Text>
    </div>
  </div>
);

const AgencyPlatform = () => {
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  return (
    <>
      <div style={{ marginTop: isDesktop ? 150 : 80 }} />
      <Container>
        <Breadcrumbs titles={["Агентская платформа"]} />
      </Container>
      <Container>
        <HeroText>
          Монетизируйте ваш нетворкинг в майнинговом комьюнити
          {!isDesktop && (
            <div style={{ marginTop: 20 }}>
              <Button href="/pending/agency-platform">Перейти на платформу →</Button>
            </div>
          )}
        </HeroText>
      </Container>
      <Container>
        <TopLineContainer>
          <Container>
            <Column half>
              <div className={s.left}>
                <div>
                  <Text>Актуально для:</Text>
                </div>
                {isDesktop && (
                  <Button href="/pending/agency-platform">Перейти на платформу →</Button>
                )}
              </div>
            </Column>
            <Column half>
              <Text>
                <ol className={s.list}>
                  <li>Хостинги</li>
                  <li>Продавцы ASIC</li>
                  <li>Владельцы телеграмм каналов</li>
                  <li>Самоорганизованные группы майнеров</li>
                </ol>
              </Text>
            </Column>
          </Container>
        </TopLineContainer>
      </Container>
      <Container>
        <HeroText>Система доходности</HeroText>
      </Container>
      <Container>
        {isDesktop && (
          <div style={{ marginBottom: 220, marginTop: 60, width: "100%" }}>
            <Table
              columns={["Статус", "Hashrate", "Pool fee", "Комиссия агента"]}
              data={data}
            />
          </div>
        )}

        {!isDesktop && (

            <div style={{ marginBottom: 50, marginTop: 30, width: "100%" }}>
            {data.map(([title, hashRate, poolFee, agentFee]) => {
              return (
                <Column>
                  <Card title={title} titleRight>
                    <InnerCard>
                      <MobileInnerCard title={"Hashrate"} value={hashRate} />
                      <InnerCard>
                        <div style={{ display: "flex", gap: 20 }}>
                          <MobileInnerCard
                              title={"Pool fee"}
                              value={poolFee}
                          />
                          <MobileInnerCard
                              title={"Комиссия агента"}
                              value={agentFee}
                          />
                        </div>
                      </InnerCard>
                    </InnerCard>
                  </Card>
                </Column>
              );
            })}
            </div>
        )}
      </Container>
      <Footer background="black" />
    </>
  );
};

export default AgencyPlatform;
