import Breadcrumbs from "../components/Breadcrumbs";
import HeroText from "../components/HeroText";
import Container from "../components/Container";
import Text from "../components/Text";
import Button from "../components/Button";
import TopLineContainer from "../components/TopLineContainer";
import Column from "../components/Column";
import s from "./agency-platform.module.css";
import Table from "../components/Table";
import Footer from "../components/Footer";
import React from "react";
import Feature from "../components/Feature";
import useMediaQuery from "../useMediaQuery";
const AgencyPlatform = () => {
  const isDesktop = useMediaQuery("(min-width: 1024px)");

  const TitleTagName = isDesktop ? "span" : "small";

  return (
    <>
      <div style={{ marginTop: isDesktop ? 150 : 80 }} />
      <Container>
        <Breadcrumbs titles={["Кастомная прошивка"]} />
      </Container>
      <Container>
        <HeroText
          right={
            isDesktop && (
              <Button href="/pending/custom-firmware">Скачать ↓</Button>
            )
          }
        >
          <span>Infinity Firmware</span>
        </HeroText>

        {!isDesktop && (
          <div style={{ marginTop: 20 }}>
            <Button href="/pending/custom-firmware">Скачать ↓</Button>
          </div>
        )}
      </Container>
      <Container>
        <TopLineContainer>
          <Feature
            title={<TitleTagName>Прозрачная статистика</TitleTagName>}
            icon={
              <svg
                width="40"
                height="41"
                viewBox="0 0 40 41"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.9991 5.45215C21.84 5.45215 23.3324 6.94453 23.3324 8.78548L23.3324 32.1188C23.3324 33.9598 21.84 35.4521 19.9991 35.4521C18.1581 35.4521 16.6657 33.9598 16.6657 32.1188L16.6657 8.78548C16.6657 6.94453 18.1581 5.45215 19.9991 5.45215Z"
                  stroke="white"
                  stroke-width="2.5"
                />
                <path
                  d="M6.66573 20.4521C8.50668 20.4521 9.99906 21.9445 9.99906 23.7855L9.99906 32.1188C9.99906 33.9598 8.50668 35.4521 6.66573 35.4521C4.82478 35.4521 3.3324 33.9598 3.3324 32.1188L3.3324 23.7855C3.3324 21.9445 4.82478 20.4521 6.66573 20.4521Z"
                  stroke="white"
                  stroke-width="2.5"
                />
                <path
                  d="M33.3324 13.7855C35.1733 13.7855 36.6657 15.2779 36.6657 17.1188V32.1188C36.6657 33.9598 35.1733 35.4521 33.3324 35.4521C31.4914 35.4521 29.9991 33.9598 29.9991 32.1188V17.1188C29.9991 15.2779 31.4914 13.7855 33.3324 13.7855Z"
                  stroke="white"
                  stroke-width="2.5"
                />
              </svg>
            }
          >
            Отображение количества часов работы вашего устройства. Внедрение
            синхронизации времени работы ASIC и времени работы в пуле.
            Уведомление на пуле при десинхронизации показателей. Это позволяет
            быть уверенным, что ваше устройство на хостинге работает только на
            вас.
          </Feature>
        </TopLineContainer>
      </Container>
      <Container>
        <TopLineContainer>
          <Feature
            title={<TitleTagName>Система отчетов</TitleTagName>}
            icon={
              <svg
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.3666 12.7427C17.3666 12.0523 17.9263 11.4927 18.6166 11.4927H27.3666C28.057 11.4927 28.6166 12.0523 28.6166 12.7427C28.6166 13.433 28.057 13.9927 27.3666 13.9927H18.6166C17.9263 13.9927 17.3666 13.433 17.3666 12.7427Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14.5172 9.35879C15.0053 9.84695 15.0053 10.6384 14.5172 11.1266L10.7672 14.8766C10.279 15.3647 9.48757 15.3647 8.99942 14.8766L7.74942 13.6266C7.26126 13.1384 7.26126 12.3469 7.74942 11.8588C8.23757 11.3706 9.02903 11.3706 9.51718 11.8588L9.8833 12.2249L12.7494 9.35879C13.2376 8.87064 14.029 8.87064 14.5172 9.35879Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.3666 24.4092C17.3666 23.7188 17.9263 23.1592 18.6166 23.1592H27.3666C28.057 23.1592 28.6166 23.7188 28.6166 24.4092C28.6166 25.0995 28.057 25.6592 27.3666 25.6592H18.6166C17.9263 25.6592 17.3666 25.0995 17.3666 24.4092Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14.5172 21.0253C15.0053 21.5135 15.0053 22.3049 14.5172 22.7931L10.7672 26.5431C10.279 27.0312 9.48757 27.0312 8.99942 26.5431L7.74942 25.2931C7.26126 24.8049 7.26126 24.0135 7.74942 23.5253C8.23757 23.0371 9.02903 23.0371 9.51718 23.5253L9.8833 23.8914L12.7494 21.0253C13.2376 20.5371 14.029 20.5371 14.5172 21.0253Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3.15776 3.10033C5.38188 0.876214 8.71003 0.0258789 13 0.0258789H23C27.2899 0.0258789 30.6181 0.876214 32.8422 3.10033C35.0663 5.32444 35.9166 8.65259 35.9166 12.9425V22.9425C35.9166 27.2325 35.0663 30.5606 32.8422 32.7848C30.6181 35.0089 27.2899 35.8592 23 35.8592H13C8.71003 35.8592 5.38188 35.0089 3.15776 32.7848C0.933648 30.5606 0.083313 27.2325 0.083313 22.9425V12.9425C0.083313 8.65259 0.933648 5.32444 3.15776 3.10033ZM4.92553 4.8681C3.39964 6.39398 2.58331 8.89917 2.58331 12.9425V22.9425C2.58331 26.9859 3.39964 29.4911 4.92553 31.017C6.45142 32.5429 8.9566 33.3592 13 33.3592H23C27.0434 33.3592 29.5485 32.5429 31.0744 31.017C32.6003 29.4911 33.4166 26.9859 33.4166 22.9425V12.9425C33.4166 8.89917 32.6003 6.39398 31.0744 4.8681C29.5485 3.34221 27.0434 2.52588 23 2.52588H13C8.9566 2.52588 6.45142 3.34221 4.92553 4.8681Z"
                  fill="white"
                />
              </svg>
            }
          >
            Получите на пуле одной кнопкой сводную статистику о вашем майнинге.
            Это создает полную прозрачность взаиморасчетов с хостингом, а
            хостинг избавляет от необходимости установки дополнительных приборов
            учета потребления.
          </Feature>
        </TopLineContainer>
      </Container>
      <Container>
        <TopLineContainer>
          <Feature
            title={<TitleTagName>Автонастройка</TitleTagName>}
            icon={
              <svg
                width="40"
                height="41"
                viewBox="0 0 40 41"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.0002 25.4199C22.7617 25.4199 25.0002 23.1813 25.0002 20.4199C25.0002 17.6585 22.7617 15.4199 20.0002 15.4199C17.2388 15.4199 15.0002 17.6585 15.0002 20.4199C15.0002 23.1813 17.2388 25.4199 20.0002 25.4199Z"
                  stroke="white"
                  stroke-width="3"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3.33356 21.8866V18.9533C3.33356 17.22 4.75022 15.7866 6.50022 15.7866C9.51689 15.7866 10.7502 13.6533 9.23356 11.0366C8.36689 9.53665 8.88356 7.58665 10.4002 6.71998L13.2836 5.06998C14.6002 4.28665 16.3002 4.75331 17.0836 6.06998L17.2669 6.38665C18.7669 9.00331 21.2336 9.00331 22.7502 6.38665L22.9336 6.06998C23.7169 4.75331 25.4169 4.28665 26.7336 5.06998L29.6169 6.71998C31.1336 7.58665 31.6502 9.53665 30.7836 11.0366C29.2669 13.6533 30.5002 15.7866 33.5169 15.7866C35.2502 15.7866 36.6836 17.2033 36.6836 18.9533V21.8866C36.6836 23.62 35.2669 25.0533 33.5169 25.0533C30.5002 25.0533 29.2669 27.1866 30.7836 29.8033C31.6502 31.32 31.1336 33.2533 29.6169 34.12L26.7336 35.77C25.4169 36.5533 23.7169 36.0866 22.9336 34.77L22.7502 34.4533C21.2502 31.8366 18.7836 31.8366 17.2669 34.4533L17.0836 34.77C16.3002 36.0866 14.6002 36.5533 13.2836 35.77L10.4002 34.12C8.88356 33.2533 8.36689 31.3033 9.23356 29.8033C10.7502 27.1866 9.51689 25.0533 6.50022 25.0533C4.75022 25.0533 3.33356 23.62 3.33356 21.8866Z"
                  stroke="white"
                  stroke-width="3"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            }
          >
            Анализирует текущее положение майнинга, учитывая доходность и
            расходы на содержание. После каждого изменения сложности майнинга
            автоматически регулирует работу вашего ASIC для максимизации прибыли
          </Feature>
        </TopLineContainer>
      </Container>
      <Container>
        <TopLineContainer>
          <Feature
            title={<TitleTagName>Современный UI/UX</TitleTagName>}
            icon={
              <svg
                width="41"
                height="41"
                viewBox="0 0 41 41"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.66693 25.8976V10.8976C3.66693 7.21573 6.6517 4.23096 10.3336 4.23096H30.3336C34.0155 4.23096 37.0003 7.21573 37.0003 10.8976V25.8976M3.66693 25.8976C3.66693 29.5795 6.6517 32.5643 10.3336 32.5643H30.3336C34.0155 32.5643 37.0003 29.5795 37.0003 25.8976M3.66693 25.8976H37.0003M20.3336 32.5643V37.5643M20.3336 37.5643H15.3336M20.3336 37.5643H25.3336M12.0003 12.5643H20.3336M12.0003 19.231H28.6669"
                  stroke="white"
                  stroke-width="2.5"
                  stroke-linecap="round"
                />
              </svg>
            }
          >
            Применим наш лучший опыт в создании интерфейсов, дружественных к
            пользователю, интуитивно понятных даже новичкам.
          </Feature>
        </TopLineContainer>
      </Container>
      <div style={{ height: 125 }} />
      <Footer background="black" />
    </>
  );
};

export default AgencyPlatform;
